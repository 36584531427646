var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.10.0

/**
 * _Without doing anything drastic_, this object lets you wire fixtures to test specs at _test definition time_. It works well with testing frameworks that use fluid APIs (like mocha or Jasmine).
 *
 * Practically speaking, this module gives you a configurable way to `bind` functions that on occasion is more expressive than the regular `Function.prototype.bind` syntax.
 *
 * It was originally used for keyhole refactoring of some large test files, thus it is super-simple and non-invasive.
 *
 *
 * 
 * e.g.
 *
 * the-test.fixtures.coffee:
 * 
 *     module.exports =
 *         beforeEach: 'What is the answer to everything?'
 *         'should equal the answer to everything':
 *             input: [40, 2]
 *             expected: 42
 *     
 * 
 * the-test.spec.coffee:
 *     
 * 
 *     fixtures = require "#{dirname}/in-is-out.fixtures"
 *    
 *     should = require "should"
 *     affix = require("affix") fixtures
 *
 *     
 *     describe 'GET /v2/websites/:website_id/sales/kpi', =>
 *         beforeEach affix.bindSet 'beforeEach', (done) ->
 *             console.log @fixture
 *             done()
 *         
 *         it affix.set('should be the answer to everything'), affix.bind (done) ->
 *             { input, expected } = @fixture
 *             actual = input[0] + input[1]
 *             actual.should.be.exactly expected
 *
 */
(function () {
  exports = function (fixtures) {
    var bind, config, fixture, fixtureKey, set, setBind;

    if ("undefined, number, string, boolean".indexOf(typeof fixtures) !== -1) {
      throw new Error("Expecting non-primative fixtures argument, instead got " + fixtures);
    }

    fixture = null;
    fixtureKey = null;
    /**
     * Set the fixture data that `set` points to
     * 
     * @param  {Object} obj the object whose key values are bound to `this.fixture`
     */

    config = function (_this) {
      return function (obj) {
        return fixtures = obj;
      };
    }(this || _global);
    /**
     * Sets the "cursor" to the wanted fixture, and returns the key unmutated.
     * It takes one input, pipes uses the input to do (unmutating work) and for output
     * 
     * Note: This is a  "T-pipe" function, ( analogy to the T-shaped plumbing pipe )
     * 
     * @param  {[type]} key [Piped]
     * @return {[type]}      [description]
     */


    set = function (_this) {
      return function (key) {
        fixtureKey = key;
        return key;
      };
    }(this || _global);
    /**
     * `bind` test fixture data to test function so it is available via `this.fixture`
     * 
     * @param  {Function} fn Statements that make assertions (using the test framework)
     * @return {Function}      Input function, bound to the active fixture
     */


    bind = function (_this) {
      return function (fn) {
        return fn.bind({
          fixture: fixtures[fixtureKey]
        });
      };
    }(this || _global);
    /**
     * Convieniance function. `set` and `bind` in one
     *
     * @param  {String} key     Optional: Key to sets the "cursor" to the wanted fixture (else falls back to last set)
     * @param  {Function} fn Statements that make assertions (using the test framework)
     * @return {Function}      Input function, bound to the active fixture
     */


    setBind = function (_this) {
      return function (key, fn) {
        set(key);
        return bind(fn);
      };
    }(this || _global);

    return {
      set: set,
      bind: bind,
      setBind: setBind,
      config: config
    };
  };
}).call(exports);
export default exports;